import Swipe from './vendor/swipe.js';
import imagesLoaded from 'imagesloaded';


'use strict';

( function() {
	const swipe_el = document.getElementById( 'swipe' );
	if ( !swipe_el ) {
		return;
	}

	if ( swipe_el.classList.contains( 'swipe--entry' ) ) {
		/* eslint-disable no-inner-declarations */
		// Lightbox
		function centreContent( elem ) {
		// Centre content
			const content = elem.querySelector( 'img, .swipe-video' );
			const window_height = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
			content.style.maxWidth = '';
			if ( content.offsetHeight > window_height ) {
				const aspect_ratio = content.offsetWidth / content.offsetHeight;
				content.style.maxWidth = ( aspect_ratio * window_height ).toString() + 'px';
			}
			content.style.marginTop = Math.round( ( window_height - content.offsetHeight ) / 2 ).toString() + 'px';
		}

		function centreAllContent( elems ) {
			for ( let j = 0; j < elems.length; j++ ) {
				centreContent( elems[j] );
			}
		}

		// Initialise Swipe
		window.mySwipe = Swipe( swipe_el, {
			startSlide: 0,
			draggable: true,
			continuous: true,
			disableScroll: true,
			stopPropagation: false,
			callback: function( index ) {
				// Update slide index label
				swipe_index.innerHTML = ( index + 1 ).toString() + '/' + window.mySwipe.getNumSlides().toString();
			},
			transitionEnd: function() {}
		} );

		// Customise Swipe
		const swipe_trigger = document.querySelectorAll( '.js-swipe-trigger' );
		const swipe_container = document.querySelector( '.swipebox' );
		const swipe_slides = swipe_container.querySelectorAll( '.swipe-wrap__item' );
		const swipe_close = document.querySelector( '.swipebox__button--close' );
		const swipe_next = document.querySelector( '.swipebox__button--next' );
		const swipe_prev = document.querySelector( '.swipebox__button--prev' );
		const swipe_index = document.querySelector( '.swipebox__index' );

		for ( let i = 0; i < swipe_trigger.length; i++ ) {
			swipe_trigger[i].addEventListener( 'click', openSwipeboxAt( i ) );
		}

		imagesLoaded( swipe_container, () => {
			centreAllContent( swipe_slides );
		} );

		window.addEventListener( 'resize', () => {
			centreAllContent( swipe_slides );
		} );

		function next() {
			window.mySwipe.next();
		}
		function prev() {
			window.mySwipe.prev();
		}

		function openSwipeboxAt( index ) {
			return function() {
				window.mySwipe.slide( index, 1 );
				swipe_container.classList.remove( 'disabled' );
				swipe_index.innerHTML = ( window.mySwipe.getPos() + 1 ).toString() + '/' + window.mySwipe.getNumSlides().toString();
			};
		}
		function hideSwipebox() {
			swipe_container.classList.add( 'disabled' );
		}

		document.addEventListener( 'keyup', ( event ) => {
			if ( event.keyCode === 27 ) { // Escape
				hideSwipebox();
			} else if ( event.keyCode === 37 ) { // Left
				prev();
			} else if ( event.keyCode === 39 ) { // Right
				next();
			}
		} );

		swipe_prev.addEventListener( 'click', () => {
			prev();
		} );

		swipe_next.addEventListener( 'click', () => {
			next();
		} );

		swipe_close.addEventListener( 'click', () => {
			hideSwipebox();
		} );

		return;
	} else {
		window.mySwipe = new Swipe( swipe_el, {
			startSlide: 0,
			auto: 10000,
			draggable: true,
			continuous: true,
			disableScroll: true,
			stopPropagation: false,
			transitionEnd: function() {}
		} );
	}

} )();

( function() {
	// Cookie notice
	if ( document.cookie.indexOf( 'seenCookieNotice' ) === -1 ) {
		document.cookie = 'seenCookieNotice=yes;path=/;max-age=31536000';
		document.body.classList.add( 'show-cookie-notice' );
		document.querySelector( '.cookie-notice button' ).addEventListener( 'click', () => {
			document.body.classList.remove( 'show-cookie-notice' );
		} );
	}
} )();

( function() {
	// Social share popups

	function popUpWindow( url ) {
		const popup_width = 550;
		const popup_height = 420;
		const x = ( window.screen.width / 2 ) - ( popup_width / 2 );
		const y = ( window.screen.height / 2 ) - ( popup_height / 2 ) - 50;
		const new_window = window.open( url, '', 'dependent=1,height=' + popup_height.toString() + ',width=' + popup_width.toString() + ',left=' + x.toString() + ',top=' + y.toString() + ',resizable=0,status=0' );
		if ( window.focus ) {
			new_window.focus();
		}
		return false;
	}

	function handleSocialShare( share_button ) {
		const url = share_button.getAttribute( 'data-share-url' );
		return function( event ) {
			event.preventDefault();
			popUpWindow( url );
		};
	}

	const social_share_buttons = document.querySelectorAll( '.social-share' );
	for ( let i = 0; i < social_share_buttons.length; i += 1 ) {
		social_share_buttons[i].addEventListener( 'click', handleSocialShare( social_share_buttons[i] ) );
	}

} )();

function createBreakerFunction( function_to_break, call_limit ) {
	const breaker_wrapper = function() {
		if ( breaker_wrapper.call_count < call_limit ) {
			breaker_wrapper.call_count += 1;
			function_to_break.apply( function_to_break, arguments );
		}
	};
	breaker_wrapper.reset = function() {
		this.call_count = 0;
	};
	breaker_wrapper.call_count = 0;
	return breaker_wrapper;
}

( function() {
	// Mobile nav
	const nav_open = document.getElementById( 'js-mobile-nav-open' );
	const nav_close = document.getElementById( 'js-mobile-nav-close' );
	nav_open.addEventListener( 'click', () => {
		document.body.classList.add( 'body-mobile-nav-open' );
	} );
	nav_close.addEventListener( 'click', () => {
		document.body.classList.remove( 'body-mobile-nav-open' );
	} );
} )();

( function() {
	// Search
	const search_toggle = document.querySelector( '.js-search' );
	const search_input = document.getElementById( 'js-search-input' );
	const search_close = document.querySelector( '.js-search-close' );

	search_toggle.addEventListener( 'click', ( i ) => {
		i.preventDefault();
		document.body.classList.toggle( 'body-search-open' );
		window.setTimeout( () => {
			// Focus after transition has finished, else browser ignores it.
			search_input.focus();
		}, 500 );
	} );

	search_close.addEventListener( 'click', () => {
		document.body.classList.remove( 'body-search-open' );
	} );

	window.addEventListener( 'keyup', ( e ) => {
		if ( e.keyCode === 27 ) { // escape key maps to keycode `27`
			document.body.classList.remove( 'body-search-open' );
		}
	} );
} )();

( function() {
	// Sticky nav
	const header = document.querySelector( '.header-inner' );
	const header_container = document.querySelector( '.header-fixed' );
	const window_width = 'innerWidth' in window ? window.innerWidth : document.documentElement.offsetWidth;
	const sticky_point = 170;

	function handleScroll( trigger_point ) {
		const on = createBreakerFunction( () => {
			header.classList.add( 'header--compact' );
			header_container.classList.add( 'header--compact' );
		}, 1 );
		const off = createBreakerFunction( () => {
			header.classList.remove( 'header--compact' );
			header_container.classList.remove( 'header--compact' );
		}, 1 );
		return function() {
			if ( window_width > 768 ) {
				const scroll_position = window.pageYOffset || document.documentElement.scrollTop;
				if ( scroll_position > trigger_point ) {
					on();
					off.reset();
				} else {
					off();
					on.reset();
				}
			}
		};
	}

	window.addEventListener( 'scroll', handleScroll( sticky_point ) );
} )();

( function() {
	// JS Parallax
	const parallax = document.querySelectorAll( '.js-parallax' );
	if ( !parallax[0] ) {
		return;
	}

	window.addEventListener( 'scroll', () => {
		const doc_offset = window.pageYOffset || document.documentElement.scrollTop;
		for ( let i = 0; i < parallax.length; i++ ) {
			parallax[i].style.transform = 'translate3d( 0px, ' + Math.round( doc_offset * 0.25 ) + 'px, 0px )';
		}
	} );
	window.addEventListener( 'resize', () => {
		const doc_offset = window.pageYOffset || document.documentElement.scrollTop;
		if ( parallax[0] ) {
			for ( let j = 0; j < parallax.length; j++ ) {
				parallax[j].style.transform = 'translate3d( 0px, ' + Math.round( doc_offset * 0.25 ) + 'px, 0px )';
			}
		}
	} );
} )();

( function() {
	// Gallery col align
	function initColumnScroller() {
		const container = document.querySelector( '.scrollmatch' );
		if ( !container ) {
			return;
		}
		if ( container.offsetHeight < ( window.innerHeight || document.documentElement.clientHeight ) ) {
			return;
		}

		const container_items = container.querySelectorAll( '.col' );
		// check we have enough elements in each col
		for ( let i = 0; i < container_items.length; i++ ) {
			const new_count = container_items[i].childNodes.length;
			if ( new_count < 2 ) {
				return;
			}
		}

		function getTallestNode( nodes ) {
			let tallest_node = nodes[0];
			for ( let i = 0; i < nodes.length; i++ ) {
				if ( nodes[i].clientHeight > tallest_node.clientHeight ) {
					tallest_node = nodes[i];
				}
			}
			return tallest_node;
		}
		function makeLoopArray( tallest_col, nodes ) {
			const a = [];
			for ( let i = 0; i < nodes.length; i++ ) {
				if ( container_items[i] !== tallest_col ) {
					a.push( container_items[i] );
				}
			}
			return a;
		}

		function handleScroll() {
			const tallest_col = getTallestNode( container_items );
			const loop_items = makeLoopArray( tallest_col, container_items );
			return () => {
				const scrollY = document.body.clientTop - tallest_col.getBoundingClientRect().top;
				const padding = window.innerHeight;
				const tallest_height = tallest_col.clientHeight;
				if ( tallest_height > window.innerHeight ) {
					for ( let i = 0; i < loop_items.length; i++ ) {
						const el = loop_items[i];
						const dx = tallest_height - el.clientHeight;
						if ( scrollY < 0 ) {
							el.style.transform = 'translateY( 0 )';
							return;
						} else if ( scrollY >= ( tallest_height - padding ) ) {
							el.style.transform = 'translateY( ' + dx + 'px )';
							return;
						}
						const trans_t = ( scrollY * dx / ( tallest_height - padding ) );
						el.style.transform = 'translateY( ' + trans_t + 'px )';
					}
				}
			};
		}

		const do_scroll_handling = handleScroll();
		window.addEventListener( 'scroll', do_scroll_handling );
		window.addEventListener( 'resize', do_scroll_handling );
		do_scroll_handling(); // Do once on page load.
	}
	if ( typeof window.requestAnimationFrame !== 'undefined' ) {
		window.addEventListener( 'load', initColumnScroller );
	}

} )();
